.country-combo-box {
    width: 100%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    transition: all 0.2s;
    outline: none;
    appearance: none;
    font-size: 1rem;
    height: auto;
    border: 0;
    background-color: transparent;
    line-height: 1.25;
    color: inherit;
    padding: 1.5rem 2rem 0.75rem 1.6rem;
    cursor: pointer;
    border-radius: 0.25rem;
}

option {
    width: 10px;
}

.country-combo-box-container {
    width: 100%;
    border: 1px solid #cfd8dc;
    border-radius: 0.25rem;
}

.input--text-label-combo {
    position: absolute;
    transition: top 0.2s, font-size 0.2s;
    left: 20px;
    padding: 0;
    height: initial;
    display: block;
    margin: 0;
    font-size: 10px;
    line-height: 12px;
    align-items: center;
    color: #546e7a;
    top: 8px;
}

.combo-box-arrow {
    position: absolute;
    display: inline-flex;
    width: 1.5rem;
    height: 100%;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    right: 0.5rem;
    top: 50%;
    pointer-events: none;
    z-index: 2;
    transform: translateY(-50%);
    color: inherit;
}
