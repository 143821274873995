.nav-bar {
    display: flex;
}

.right-corner {
    width: 90px;
    justify-content: right;
    display: flex;
    align-content: center;
    align-items: center;
    margin-right: 10px;
    margin-left: 10px;
}

.logo-img {
    display: flex;
    align-content: center;
    align-items: center;
    margin-right: auto;
    margin-left: 10px;
}

.back-button {
    width: 90px;
    display: flex;
    cursor: pointer;
    align-items: center;
    margin-right: 10px;
    margin-left: 10px;
}

.back-button--img {
    width: 1rem;
    height: 1rem;
    color: currentcolor;
    display: inline-block;
    vertical-align: middle;
    margin-right: 1rem;
    flex-shrink: 0;
    backface-visibility: hidden;
}

.back-button--text {
    font-family: proxima-soft, sans-serif;
    font-size: 1.125rem;
    font-weight: 800;
    vertical-align: middle;
}


@media screen and (max-width: 770px) and (max-height: 1030px) and (orientation:portrait), screen and (max-height: 770px) and (max-width: 1030px) and (orientation:landscape) {
    .logo-img {
        margin-left: auto;
    }

    .margin-left-mobile {
        margin-left: 10px;
    }
}
