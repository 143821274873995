.centered-spinner {
    display: flex;
    justify-content: center;
}

.spinner-non-fixed {
    margin-top: 80px;
    margin-bottom: 80px;
    left: calc(50% - 30px);
    top: calc(50% - 30px);
    height:60px;
    width:60px;
    -webkit-animation: rotation .6s infinite linear;
    -moz-animation: rotation .6s infinite linear;
    -o-animation: rotation .6s infinite linear;
    animation: rotation .6s infinite linear;
    border-left:6px solid rgba(0,174,239,.15);
    border-right:6px solid rgba(0,174,239,.15);
    border-bottom:6px solid rgba(0,174,239,.15);
    border-top:6px solid rgba(0,174,239,.8);
    border-radius:100%;
}
