span.terms {
  border: 1px solid #eceff1;
  box-sizing: border-box;
  border-radius: 4px;
  display: block;
  width: 100%;
  padding: 20px 25px;
  text-align: center;
  margin: 24px 0;
}

span.terms a {
  color: #1c2428;
  text-decoration-color: #1c2428;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: white;
  border: 1px solid #cfd8dc;
  box-sizing: border-box;
  border-radius: 4px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-container {
  margin: 11px 0 -4px;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-container input:focus ~ .checkmark {
  border-color: #546e7a;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  width: 12px;
  height: 12px;
  background: #039be5;
  border-radius: 2px;
}

.rating-image {
  max-width: 344px;
  margin-top: -26px;
  margin-bottom: 20px;
}

.input--text-nameoncard-label {
  position: absolute;
  transition: top 0.2s, font-size 0.2s;
  top: 26px;
  left: 24px;
  color: #a0b1b9;
  font-size: 18px;
  font-family: proxima-nova;
  padding: 0;
  height: initial;
  display: block;
  margin: 0;
}

.input--text-nameoncard-label-active {
  font-size: 10px;
  line-height: 12px;
  align-items: center;
  color: #546e7a;
  top: 15px;
}

.input--promo-applied {
  background-color: #fafbfb;
  cursor: default;
}

.InputContainer .InputElement {
  padding-left: 8px;
}

.radio-container {
  display: flex !important;
  flex: 1;
  position: relative;
  align-items: center;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: proxima-soft;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  color: #1c2428;
  margin: 4px 0px 8px;
  border: 1px solid #cfd8dc;
  padding: 18px 74px;
  border-radius: 12px;
  line-height: 28px;
}

.radio-container p{
 display: inline-block;
}

.radio-container:hover {
  border: 1px solid #000000;
}

.radio-container--checked {
  border: 1px solid #039be5;
}

.radio-container--yearly {
  margin-top: 24px;
}

/* Hide the browser's default radio button */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.radio-checkmark {
  top:24px !important;
  height: 32px;
  width: 32px;
  background-color: #ffffff;
  border: 1px solid #cfd8dc;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radio-container:hover input ~ .radio-checkmark {
  background-color: #ffffff;
}

/* When the radio button is checked, add a blue background */
.radio-container input:checked ~ .radio-checkmark {
  background-color: #ffffff;
  border: 1px solid #cfd8dc;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radio-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .radio-checkmark:after {
  display: block;
  background: #039be5;
  border: 0.5px solid #cfd8dc;
}

/* Style the indicator (dot/circle) */
.radio-container .radio-checkmark:after {
  border-radius: 50%;
  background: white;
  top: 5px;
  left: 5px;
  width: 20px;
  height: 20px;
}

.check-container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: proxima-nova;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #1c2428;
  margin: 4px 0px 8px;
  border: none;
  padding: 16px 36px;
  height: 80px;
  box-sizing: border-box;
  border-radius: 12px;
}

/* Hide the browser's default check button */
.check-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom check button */
.check-checkmark {
  position: absolute;
  top: 12px;
  left: 0px;
  height: 24px;
  width: 24px;
  background-color: #ffffff;
  border: 1px solid #cfd8dc;
  border-radius: 4px;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.check-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Style the indicator (dot/circle) */
.check-container .check-checkmark:after {
  border-radius: 50%;
  background: white;
  top: 5px;
  left: 5px;
  width: 20px;
  height: 20px;
}

.css-isurb6 {
  position: absolute;
  width: 100%;
  left: 0px;
  text-align: center;
  top: -22px;
}

.css-s6blyv {
  padding: 0px 14px;
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  border-color: #cfd8dc;
  margin: 0px;
  font-style: normal;
  font-size: 14px;
  font-weight: 500;
  background-color: rgb(225, 245, 254);
}

.css-nm28jr {
  padding: 0px 14px;
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  border-color: #cfd8dc;
  margin: 0px;
  font-style: normal;
  font-size: 14px;
  font-weight: 500;
  background-color: rgb(255, 255, 255);
}

.css-c4ccvv {
  font-family: proxima-soft, sans-serif;
  color: rgb(36, 82, 246);
}

.radio-container--checked .css-s6blyv,
.radio-container--checked .css-nm28jr {
  border-color: #039be5;
}

.radio-container:hover .css-s6blyv,
.radio-container:hover .css-nm28jr {
  border-color: #000000;
}

.legal {
  border: 1px solid #eceff1;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 24px;
  font-size: 15px;
  margin: 32px 0 24px;
}

.legal-divider {
  background: #eceff1;
  height: 1px;
  display: block;
  margin: 24px -8px;
}

.legal-pricing {
  font-size: 16px;
  line-height: 160%;
  position: relative;
}

.legal-pricing .free-period {
  position: absolute;
  right: 20px;
  font-weight: bold;
}

.legal-pricing .paid-period {
  position: absolute;
  right: 0;
}

.legal-terms {
  line-height: 135%;
}

.span-promo-code {
  font-family: proxima-soft;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  text-align: right;
  position: absolute;
  right: 24px;
  top: 28px;
  cursor: pointer;
}

.legal-pricing--promo {
  background: #e1f5fe;
  border-radius: 4px;
}

.promo-period {
  position: absolute;
  right: 0;
  font-weight: bold;
}

.span-promo-code--apply {
  color: #039be5;
}

.span-promo-code--disabled {
  color: #455a64;
  cursor: not-allowed !important;
}

.span-promo-code--remove {
  color: #455a64;
}
