.not-found-bg-image {
    font-family: proxima-soft, sans-serif;
    -webkit-font-smoothing: antialiased;
    text-size-adjust: 100%;
    text-rendering: optimizelegibility;
    background-attachment: fixed;
    background-image: url(https://watch.vooks.com/static/404.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom right;
    height: 100%;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: -1;
    display: block;
}

.not-found-content {
    margin-top: 18%;
    margin-left: 10%;
}

.not-found-main-title {
    font-family: proxima-soft,sans-serif;
    color: #1A202C;
    font-size: 4rem;
    font-weight: 800;
    line-height: 1.25;
    margin: 0;
}


.not-found-text {
    max-width: 28rem;
    margin-top: 2rem;
    font-size: 2.25rem;
    font-weight: 800;
    line-height: 1.25;
    color: #1A202C;
    white-space: break-spaces;
}

.not-found-text-i {
    max-width: 28rem;
    font-size: 2.25rem;
    font-weight: 800;
    line-height: 1.25;
    font-family: proxima-soft,sans-serif;
    color: #ef6c00;
    white-space: break-spaces;
}

.not-found-button {
    margin-top: 2rem;
    font-family: proxima-soft,sans-serif;
    user-select: none;
    position: relative;
    white-space: nowrap;
    vertical-align: middle;
    line-height: 1.2;
    outline: none;
    height: 2.5rem;
    min-width: 2.5rem;
    font-size: 1.125rem;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: #2452f6;
    color: #ffffff;
    cursor: pointer;
    transition: all 250ms;
    justify-content: center;
    align-items: center;
    appearance: none;
    display: inline-flex;
    border-radius: 0.25rem;
    font-weight: 800;
    width: 12rem;
}

.not-found-button-arrow {
    width: 1em;
    height: 1em;
    color: white;
    display: inline-block;
    vertical-align: middle;
    margin-left: -0.25rem;
    margin-right: 0.5rem;
    backface-visibility: hidden;
}
