span.terms {
  border: 1px solid #eceff1;
  box-sizing: border-box;
  border-radius: 4px;
  display: block;
  width: 100%;
  padding: 20px 25px;
  text-align: center;
  margin: 24px 0;
}

span.terms a {
  color: #1c2428;
  text-decoration-color: #1c2428;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: white;
  border: 1px solid #cfd8dc;
  box-sizing: border-box;
  border-radius: 4px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-container {
  margin: 11px 0 -4px;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-container input:focus ~ .checkmark {
  border-color: #546e7a;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  width: 12px;
  height: 12px;
  background: #039be5;
  border-radius: 2px;
}

.gift-vooks {
  margin-top: 30px;
}

.gift-vooks-mobile {
  margin-top: 20px;
  margin-bottom: 20px;
  display: none;
}

.gift-vooks:hover {
  text-decoration: underline;
  cursor: pointer;
}

div.gift-vooks img {
  margin-right: 10px;
  vertical-align: top;
}

div.gift-vooks-mobile img {
  margin-right: 10px;
  vertical-align: top;
}

textarea.input--text {
  border: 1px solid rgb(207, 216, 220);
  box-sizing: border-box;
  border-radius: 4px;
  outline: none;
  margin: 4px 0px;
  padding: 10px;
}

span.gift-subtitle {
  margin: 16px 0;
  display: block;
  font-weight: 600;
  font-family: proxima-soft;
}

.rating-image {
  max-width: 344px;
  margin-top: -26px;
  margin-bottom: 20px;
}

.input--button__help-text {
  text-align: center;
  display: block;
}

.trust-pilot-image {
  display: block;
  width: 45%;
  height: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

@media screen and (max-width: 770px) and (max-height: 1030px) and (orientation:portrait), screen and (max-height: 770px) and (max-width: 1030px) and (orientation:landscape) {
  div.hero {
    margin: 0 auto 10px !important;
  }
}


