@import url(https://p.typekit.net/p.css?s=1&k=kbk5xha&ht=tk&f=139.173.175.25136.32902.32904.32905&a=46143091&app=typekit&e=css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * proxima-nova:
 *   - http://typekit.com/eulas/00000000000000003b9b3068
 *   - http://typekit.com/eulas/00000000000000003b9b3066
 *   - http://typekit.com/eulas/00000000000000003b9b3062
 *   - http://typekit.com/eulas/00000000000000003b9b3064
 * proxima-soft:
 *   - http://typekit.com/eulas/00000000000000003b9b3036
 *   - http://typekit.com/eulas/00000000000000003b9b3038
 *   - http://typekit.com/eulas/00000000000000003b9b303a
 *
 * © 2009-2020 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2020-08-13 19:59:56 UTC"}*/

@font-face {
font-family:"proxima-nova";
src:url("https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:700;
}

@font-face {
font-family:"proxima-nova";
src:url("https://use.typekit.net/af/576d53/00000000000000003b9b3066/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n6&v=3") format("woff2"),url("https://use.typekit.net/af/576d53/00000000000000003b9b3066/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n6&v=3") format("woff"),url("https://use.typekit.net/af/576d53/00000000000000003b9b3066/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n6&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:600;
}

@font-face {
font-family:"proxima-nova";
src:url("https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:400;
}

@font-face {
font-family:"proxima-nova";
src:url("https://use.typekit.net/af/6e816b/00000000000000003b9b3064/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/6e816b/00000000000000003b9b3064/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/6e816b/00000000000000003b9b3064/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n5&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:500;
}

@font-face {
font-family:"proxima-soft";
src:url("https://use.typekit.net/af/f4a012/00000000000000003b9b3036/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n6&v=3") format("woff2"),url("https://use.typekit.net/af/f4a012/00000000000000003b9b3036/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n6&v=3") format("woff"),url("https://use.typekit.net/af/f4a012/00000000000000003b9b3036/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n6&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:600;
}

@font-face {
font-family:"proxima-soft";
src:url("https://use.typekit.net/af/2657d4/00000000000000003b9b3038/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/2657d4/00000000000000003b9b3038/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/2657d4/00000000000000003b9b3038/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:700;
}

@font-face {
font-family:"proxima-soft";
src:url("https://use.typekit.net/af/889e08/00000000000000003b9b303a/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n8&v=3") format("woff2"),url("https://use.typekit.net/af/889e08/00000000000000003b9b303a/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n8&v=3") format("woff"),url("https://use.typekit.net/af/889e08/00000000000000003b9b303a/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n8&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:800;
}

.tk-proxima-nova { font-family: "proxima-nova",sans-serif; }
.tk-proxima-soft { font-family: "proxima-soft",sans-serif; }

.App {
  font-family: proxima-nova;
  color: #1C2428;
  font-size: 16px;
  text-align: center;
  padding: 16px;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

header {
  position: relative;
  padding-bottom: 10px;
  text-align: left;
}

.logo {
  cursor: pointer;
}

.login-link {
  text-decoration: none;
  font-family: proxima-soft;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 10px;
  color: #1C2428;
  margin: 16px 0px;
}

h1 {
  font-family: proxima-soft;
  font-size: 32px;
  font-weight: 800;
  margin: 8px 8px;
  line-height: 110%;
}

h2 {
  font-family: proxima-soft;
  font-size: 18px;
  font-weight: bold;
  margin: 8px 0 28px;
}

input {
  border: 1px solid #CFD8DC;
  box-sizing: border-box;
  border-radius: 4px;
  outline: none;
  -webkit-appearance: none;
}

input:focus {
  border-color: #546E7A;
}

div.hero {
  max-width: 367px;
  margin: 0 auto 40px;
}

.alert {
  background: #D03B35;
  border-radius: 12px;
  padding: 12px 40px 12px 24px;
  color: #ffffff;
  font-weight: 600;
  position: fixed;
  z-index: 1;
  top: 10px;
  left: max(8px, calc((100% - 492px)/2));
  width: calc(100% - 80px);
  max-width: 452px;
}

@media(max-width: 700px) {
  .alert {
    border-radius: 0;
    border-radius: initial;
    top: 0;
    left: 0;
    right: 0;
    width: auto;
    max-width: none;
    max-width: initial;
  }
}

.alert-dismiss {
  border: none;
  background: none;
  right: 10px;
  top: 8px;
  position: absolute;
}

.alert-dismiss:focus {
  outline: none;
}

.spinner {
   position: fixed;
   left: calc(50% - 30px);
   top: calc(50% - 30px);
   height:60px;
   width:60px;
   margin:0px auto;
   -webkit-animation: rotation .6s infinite linear;
   animation: rotation .6s infinite linear;
   border-left:6px solid rgba(0,174,239,.15);
   border-right:6px solid rgba(0,174,239,.15);
   border-bottom:6px solid rgba(0,174,239,.15);
   border-top:6px solid rgba(0,174,239,.8);
   border-radius:100%;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 2
}

@-webkit-keyframes rotation {
   from {-webkit-transform: rotate(0deg);}
   to {-webkit-transform: rotate(359deg);}
}
@keyframes rotation {
   from {transform: rotate(0deg);}
   to {transform: rotate(359deg);}
}

.input--text {
  height: 56px;
  font-size: 16px;
  padding: 20px 24px 8px 24px;
  font-family: proxima-nova;
  width: 100%;
  margin: 4px 0px;
  resize: vertical;
}

.input--nameoncard {
  margin: 8px 0;
}

.input--text:focus ~ label {
  font-size: 10px;
  line-height: 12px;
  align-items: center;
  color: #546e7a;
  top: 13px;
}

.input-with-label {
  position: relative;
}

.input--text-label {
  position: absolute;
  transition: top 0.2s, font-size 0.2s;
  top: 21px;
  left: 29px;
  color: #a0b1b9;
  font-size: 18px;
  font-family: proxima-nova;
  padding: 0;
  height: auto;
  height: initial;
  display: block;
  margin: 0;
}

.input--text-label-active {
  font-size: 10px;
  line-height: 12px;
  align-items: center;
  color: #546e7a;
  top: 13px;
}

.input--checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

div.step {
  text-align: left;
  max-width: 343px;
  margin: 10px auto;
}

span.step-time {
  font-size: 14px;
}

span.step-savings {
  background: #e1f5fe;
  border-radius: 100px;
  font-size: 12px;
  position: absolute;
  right: 24px;
  top: 26px;
  padding: 4px 12px;
  color: #01579b;
}

span.step-no-charge {
  background: #eceff1;
  border-radius: 4px;
  font-size: 15px;
  line-height: 130%;
  display: block;
  font-weight: bold;
  padding: 16px;
  text-align: center;
  margin: 24px 0 16px 0;
}

.input--button {
  color: #ffffff;
  background: #2452f6;
  border-radius: 100px;
  font-family: proxima-soft;
  font-weight: 800;
  font-size: 18px;
  text-align: center;
  height: 56px;
  width: 100%;
  border: none;
  outline: none;
  cursor: pointer;
}

.input--button.disabled,
.input--button_secondary.disabled {
  pointer-events: none;
  opacity: 0.7;
}

.input--button_secondary {
  color: #1c2428;
  background: #ffffff;
  border: 1px solid #cfd8dc;
  border-radius: 100px;
  font-family: proxima-soft;
  font-weight: 800;
  font-size: 18px;
  text-align: center;
  height: 56px;
  width: 100%;
  outline: none;
  cursor: pointer;
}

.steps-container {
  padding-bottom: 16px;
  font-family: proxima-soft, sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
          text-size-adjust: 100%;
  text-rendering: optimizelegibility;
  line-height: 1.5;
  box-sizing: border-box;
  border-width: 0px;
  border-style: solid;
  border-color: rgb(226, 232, 240);
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  color: rgb(28, 36, 40);
  display: flex;
  flex-direction: column;
  margin: 0 auto 2rem;
  max-width: 32rem;
  padding: 1rem 1.5rem 2rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  width: 100%;
}

.step h2 {
  margin-bottom: 0px;
  font-weight: 800;
  font-size: 1.3rem;
}

.link {
  margin-top: 5px;
  display: flex;
  justify-content: center;
}

.link a {
  color: #1c2428;
  -webkit-text-decoration-color: #1c2428;
          text-decoration-color: #1c2428;
}

.not-found-bg-image {
    font-family: proxima-soft, sans-serif;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
       -moz-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
            text-size-adjust: 100%;
    text-rendering: optimizelegibility;
    background-attachment: fixed;
    background-image: url(https://watch.vooks.com/static/404.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom right;
    height: 100%;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: -1;
    display: block;
}

.not-found-content {
    margin-top: 18%;
    margin-left: 10%;
}

.not-found-main-title {
    font-family: proxima-soft,sans-serif;
    color: #1A202C;
    font-size: 4rem;
    font-weight: 800;
    line-height: 1.25;
    margin: 0;
}


.not-found-text {
    max-width: 28rem;
    margin-top: 2rem;
    font-size: 2.25rem;
    font-weight: 800;
    line-height: 1.25;
    color: #1A202C;
    white-space: break-spaces;
}

.not-found-text-i {
    max-width: 28rem;
    font-size: 2.25rem;
    font-weight: 800;
    line-height: 1.25;
    font-family: proxima-soft,sans-serif;
    color: #ef6c00;
    white-space: break-spaces;
}

.not-found-button {
    margin-top: 2rem;
    font-family: proxima-soft,sans-serif;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    position: relative;
    white-space: nowrap;
    vertical-align: middle;
    line-height: 1.2;
    outline: none;
    height: 2.5rem;
    min-width: 2.5rem;
    font-size: 1.125rem;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: #2452f6;
    color: #ffffff;
    cursor: pointer;
    transition: all 250ms;
    justify-content: center;
    align-items: center;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    display: inline-flex;
    border-radius: 0.25rem;
    font-weight: 800;
    width: 12rem;
}

.not-found-button-arrow {
    width: 1em;
    height: 1em;
    color: white;
    display: inline-block;
    vertical-align: middle;
    margin-left: -0.25rem;
    margin-right: 0.5rem;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
}

.nav-bar {
    display: flex;
}

.right-corner {
    width: 90px;
    justify-content: right;
    display: flex;
    align-content: center;
    align-items: center;
    margin-right: 10px;
    margin-left: 10px;
}

.logo-img {
    display: flex;
    align-content: center;
    align-items: center;
    margin-right: auto;
    margin-left: 10px;
}

.back-button {
    width: 90px;
    display: flex;
    cursor: pointer;
    align-items: center;
    margin-right: 10px;
    margin-left: 10px;
}

.back-button--img {
    width: 1rem;
    height: 1rem;
    color: currentcolor;
    display: inline-block;
    vertical-align: middle;
    margin-right: 1rem;
    flex-shrink: 0;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
}

.back-button--text {
    font-family: proxima-soft, sans-serif;
    font-size: 1.125rem;
    font-weight: 800;
    vertical-align: middle;
}


@media screen and (max-width: 770px) and (max-height: 1030px) and (orientation:portrait), screen and (max-height: 770px) and (max-width: 1030px) and (orientation:landscape) {
    .logo-img {
        margin-left: auto;
    }

    .margin-left-mobile {
        margin-left: 10px;
    }
}

span.terms {
  border: 1px solid #eceff1;
  box-sizing: border-box;
  border-radius: 4px;
  display: block;
  width: 100%;
  padding: 20px 25px;
  text-align: center;
  margin: 24px 0;
}

span.terms a {
  color: #1c2428;
  -webkit-text-decoration-color: #1c2428;
          text-decoration-color: #1c2428;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: white;
  border: 1px solid #cfd8dc;
  box-sizing: border-box;
  border-radius: 4px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-container {
  margin: 11px 0 -4px;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-container input:focus ~ .checkmark {
  border-color: #546e7a;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  width: 12px;
  height: 12px;
  background: #039be5;
  border-radius: 2px;
}

.gift-vooks {
  margin-top: 30px;
}

.gift-vooks-mobile {
  margin-top: 20px;
  margin-bottom: 20px;
  display: none;
}

.gift-vooks:hover {
  text-decoration: underline;
  cursor: pointer;
}

div.gift-vooks img {
  margin-right: 10px;
  vertical-align: top;
}

div.gift-vooks-mobile img {
  margin-right: 10px;
  vertical-align: top;
}

textarea.input--text {
  border: 1px solid rgb(207, 216, 220);
  box-sizing: border-box;
  border-radius: 4px;
  outline: none;
  margin: 4px 0px;
  padding: 10px;
}

span.gift-subtitle {
  margin: 16px 0;
  display: block;
  font-weight: 600;
  font-family: proxima-soft;
}

.rating-image {
  max-width: 344px;
  margin-top: -26px;
  margin-bottom: 20px;
}

.input--button__help-text {
  text-align: center;
  display: block;
}

.trust-pilot-image {
  display: block;
  width: 45%;
  height: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

@media screen and (max-width: 770px) and (max-height: 1030px) and (orientation:portrait), screen and (max-height: 770px) and (max-width: 1030px) and (orientation:landscape) {
  div.hero {
    margin: 0 auto 10px !important;
  }
}



.SwipeButton {
  width: 100%;
  height: 56px;
  position: relative;
}
.SwipeButton,
.SwipeButton * {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */ /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    not supported by any browser */
}
.rsbContainer {
  float: left;
  width: 100%;
  height: 100%;
  background: #bed8fe;
  border-radius: 100px;
  position: relative;
  /* box-shadow: inset 1px 1px 4px rgba(0,0,0,0.1); */
  overflow: hidden;
}
.rsbContainerUnlocked {
  width: 50% !important;
  margin-left: 25%;
  transition: 0.5s;
  cursor: default;
}
.rsbContainerUnlocked .rsbcSlider {
  left: 100% !important;
  cursor: default;
  pointer-events: none;
}
.rsbContainerUnlocked .rsbcSliderArrow {
  transition: 0.5s;
  margin-right: -60px;
}
.rsbContainerUnlocked .rsbcSliderCircle {
  transition: 0.5s;
  margin-right: -60px;
}
.rsbcSlider {
  float: left;
  width: 100%;
  position: absolute;
  height: 56px;
  top: 0;
  left: 56px;
  margin-left: -100%;
  background: #333;
  border-radius: 999px;
  z-index: 100;
  /* box-shadow: 1px 1px 5px rgba(0,0,0,0.3); */
  cursor: pointer;
}
.rsbcSliderText {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  line-height: 56px;
  text-align: center;
  font-family: proxima-soft;
  font-weight: 800;
  font-size: 18px;
  color: #fff;
}
.rsbcSliderArrow {
  position: absolute;
  top: 25px;
  color: white;
  right: 22px;
  margin-top: -6px;
  transform-origin: center;
  z-index: 10;
}
.rsbcSliderCircle {
  position: absolute;
  right: 0;
  background: #444;
  top: 0;
  height: 56px;
  width: 56px;
  border-radius: 100%;
}
.rsbcText {
  font-style: normal;
  letter-spacing: 0em;
  float: left;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 56px;
  line-height: 56px;
  text-align: center;
  font-family: proxima-soft;
  font-weight: 800;
  font-size: 18px;
  color: #2452f6;
}

.StripeElement--complete ~ button.stripe-submit {
  background: #2452F6;
  color: #ffffff;
}

.legal {
  border: 1px solid #ECEFF1;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 24px;
  font-size: 15px;
  margin: 32px 0 24px;
}

.legal-divider {
  background: #ECEFF1;
  height: 1px;
  display: block;
  margin: 24px -8px;
}

.legal-pricing {
  font-size: 16px;
  line-height: 160%;
  position: relative;
}

.legal-pricing .free-period {
  position: absolute;
  right: 20px;
  font-weight: bold;
}

.legal-pricing .paid-period {
  position: absolute;
  right: 0;
}

.legal-terms {
  line-height: 135%;
}

.span-promo-code {
  font-family: proxima-soft;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  text-align: right;
  position: absolute;
  right: 24px;
  top: 28px;
  cursor: pointer;
}

.legal-pricing--promo {
  background: #E1F5FE;
  border-radius: 4px;
}

.promo-period {
  position: absolute;
  right: 0;
  font-weight: bold;
}

.span-promo-code--apply {
  color: #039BE5;
}

.span-promo-code--disabled {
  color: #455a64;
  cursor: not-allowed !important;
}

.span-promo-code--remove {
  color: #455A64;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.vertical-panel {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.common-checkout-btn {
  color: white;
  font-size: 15px;
  background: #1C2428;
  border: none;
  border-radius: 100px;
  font-family: proxima-soft;
  font-weight: 800;
  font-size: 18px;
  cursor: pointer;
  width: 100%;
  height: 56px;
}

.amazon-checkout-btn {
  margin: 20px 0;
  height: 60px;
  width: 100%;
}

.amazon-checkout-btn-hidden {
  display: none;
}

.change-payment-method-btn {
  color: white;
  font-size: 15px;
  background: #1C2428;
  border: none;
  border-radius: 100px;
  font-family: proxima-soft;
  font-weight: 800;
  font-size: 18px;
  cursor: pointer;
  width: 100%;
  height: 50px;
}

.payment-method-selection-divider{
  margin-top: 10px;
  margin-bottom: 10px;
}

.input--text-nameoncard-label {
  position: absolute;
  transition: top .2s, font-size .2s;
  top: 26px;
  left: 24px;
  color: #A0B1B9;
  font-size: 18px;
  font-family: proxima-nova;
  padding: 0;
  height: auto;
  height: initial;
  display: block;
  margin: 0;
}

.input--text-nameoncard-label-active {
  font-size: 10px;
  line-height: 12px;
  align-items: center;
  color: #546E7A;
  top: 15px;
}

.input--promo-applied {
  background-color: #FAFBFB;
}

.InputContainer .InputElement {
  padding-left: 8px;
}

.radio-container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: proxima-soft;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  color: #1C2428;
  margin: 4px 0px 8px;
  border: 1px solid #CFD8DC;
  padding: 18px 74px;
  height: 80px;
  box-sizing: border-box;
  border-radius: 12px;
}

.radio-container:hover {
  border: 1px solid #000000;
}

.radio-container--checked {
  border: 1px solid #039BE5;
}

.radio-container--yearly {
  margin-top: 24px;
}

/* Hide the browser's default radio button */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.radio-checkmark {
  position: absolute;
  top: 20px;
  left: 24px;
  height: 32px;
  width: 32px;
  background-color: #ffffff;
  border: 1px solid #CFD8DC;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radio-container:hover input ~ .radio-checkmark {
  background-color: #ffffff;
}

/* When the radio button is checked, add a blue background */
.radio-container input:checked ~ .radio-checkmark {
  background-color: #ffffff;
  border: 1px solid #CFD8DC;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radio-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .radio-checkmark:after {
  display: block;
  background: #039BE5;
  border: 0.5px solid #CFD8DC;
}

/* Style the indicator (dot/circle) */
.radio-container .radio-checkmark:after {
  border-radius: 50%;
  background: white;
  top: 5px;
  left: 5px;
  width: 20px;
  height: 20px;
}


.check-container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: proxima-nova;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #1C2428;
  margin: 4px 0px 8px;
  border: none;
  padding: 16px 36px;
  height: 80px;
  box-sizing: border-box;
  border-radius: 12px;
}

/* Hide the browser's default check button */
.check-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom check button */
.check-checkmark {
  position: absolute;
  top: 12px;
  left: 0px;
  height: 24px;
  width: 24px;
  background-color: #ffffff;
  border: 1px solid #CFD8DC;
  border-radius: 4px;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.check-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Style the indicator (dot/circle) */
.check-container .check-checkmark:after {
  border-radius: 50%;
  background: white;
  top: 5px;
  left: 5px;
  width: 20px;
  height: 20px;
}

.css-isurb6 {
  position: absolute;
  width: 100%;
  left: 0px;
  text-align: center;
  top: -22px;
}

.css-s6blyv {
  padding: 0px 14px;
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  border-color: #CFD8DC;
  margin: 0px;
  font-style: normal;
  font-size: 14px;
  font-weight: 500;
  background-color: rgb(225, 245, 254);
}

.css-nm28jr {
  padding: 0px 14px;
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  border-color: #CFD8DC;
  margin: 0px;
  font-style: normal;
  font-size: 14px;
  font-weight: 500;
  background-color: rgb(255, 255, 255);
}

.css-c4ccvv {
  font-family: proxima-soft, sans-serif;
  color: rgb(36, 82, 246);
}

.radio-container--checked .css-s6blyv, .radio-container--checked .css-nm28jr {
  border-color: #039BE5;
}

.radio-container:hover .css-s6blyv, .radio-container:hover .css-nm28jr {
  border-color: #000000;
}

.form {
  margin-top: 20px;
  margin-bottom: 20px;
}

.topic-divider {
  border-top: 1px solid #b4c4cd;
}


.centered-spinner {
    display: flex;
    justify-content: center;
}

.spinner-non-fixed {
    margin-top: 80px;
    margin-bottom: 80px;
    left: calc(50% - 30px);
    top: calc(50% - 30px);
    height:60px;
    width:60px;
    -webkit-animation: rotation .6s infinite linear;
    animation: rotation .6s infinite linear;
    border-left:6px solid rgba(0,174,239,.15);
    border-right:6px solid rgba(0,174,239,.15);
    border-bottom:6px solid rgba(0,174,239,.15);
    border-top:6px solid rgba(0,174,239,.8);
    border-radius:100%;
}

.pricing-summary {
  border: 1px solid #ECEFF1;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 24px;
  font-size: 16px;
  margin: 32px 0 24px;
}

.pricing-summary__subscription {
  text-transform: capitalize;
}

.pricing-summary__subscription--price {
  float: right;
}

.pricing-summary__total--price {
  float: right;
  font-weight: 800;
}

.pricing-summary--divider {
  background: #ECEFF1;
  height: 1px;
  display: block;
  margin-top: 16px;
  margin-bottom: 16px;
}

.legal-pricing {
  font-size: 16px;
  line-height: 160%;
  position: relative;
}

.amazon-pay-logo {
  width: 90px;
  height: 26px;
}

.step3__customer_exists {
  text-align: center;
}

.step3__customer_exists span {
  margin-bottom: 10px;
  display: block;
}

span.terms {
  border: 1px solid #eceff1;
  box-sizing: border-box;
  border-radius: 4px;
  display: block;
  width: 100%;
  padding: 20px 25px;
  text-align: center;
  margin: 24px 0;
}

span.terms a {
  color: #1c2428;
  -webkit-text-decoration-color: #1c2428;
          text-decoration-color: #1c2428;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: white;
  border: 1px solid #cfd8dc;
  box-sizing: border-box;
  border-radius: 4px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-container {
  margin: 11px 0 -4px;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-container input:focus ~ .checkmark {
  border-color: #546e7a;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  width: 12px;
  height: 12px;
  background: #039be5;
  border-radius: 2px;
}

.rating-image {
  max-width: 344px;
  margin-top: -26px;
  margin-bottom: 20px;
}

.input--text-nameoncard-label {
  position: absolute;
  transition: top 0.2s, font-size 0.2s;
  top: 26px;
  left: 24px;
  color: #a0b1b9;
  font-size: 18px;
  font-family: proxima-nova;
  padding: 0;
  height: auto;
  height: initial;
  display: block;
  margin: 0;
}

.input--text-nameoncard-label-active {
  font-size: 10px;
  line-height: 12px;
  align-items: center;
  color: #546e7a;
  top: 15px;
}

.input--promo-applied {
  background-color: #fafbfb;
  cursor: default;
}

.InputContainer .InputElement {
  padding-left: 8px;
}

.radio-container {
  display: flex !important;
  flex: 1 1;
  position: relative;
  align-items: center;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: proxima-soft;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  color: #1c2428;
  margin: 4px 0px 8px;
  border: 1px solid #cfd8dc;
  padding: 18px 74px;
  border-radius: 12px;
  line-height: 28px;
}

.radio-container p{
 display: inline-block;
}

.radio-container:hover {
  border: 1px solid #000000;
}

.radio-container--checked {
  border: 1px solid #039be5;
}

.radio-container--yearly {
  margin-top: 24px;
}

/* Hide the browser's default radio button */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.radio-checkmark {
  top:24px !important;
  height: 32px;
  width: 32px;
  background-color: #ffffff;
  border: 1px solid #cfd8dc;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radio-container:hover input ~ .radio-checkmark {
  background-color: #ffffff;
}

/* When the radio button is checked, add a blue background */
.radio-container input:checked ~ .radio-checkmark {
  background-color: #ffffff;
  border: 1px solid #cfd8dc;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radio-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .radio-checkmark:after {
  display: block;
  background: #039be5;
  border: 0.5px solid #cfd8dc;
}

/* Style the indicator (dot/circle) */
.radio-container .radio-checkmark:after {
  border-radius: 50%;
  background: white;
  top: 5px;
  left: 5px;
  width: 20px;
  height: 20px;
}

.check-container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: proxima-nova;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #1c2428;
  margin: 4px 0px 8px;
  border: none;
  padding: 16px 36px;
  height: 80px;
  box-sizing: border-box;
  border-radius: 12px;
}

/* Hide the browser's default check button */
.check-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom check button */
.check-checkmark {
  position: absolute;
  top: 12px;
  left: 0px;
  height: 24px;
  width: 24px;
  background-color: #ffffff;
  border: 1px solid #cfd8dc;
  border-radius: 4px;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.check-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Style the indicator (dot/circle) */
.check-container .check-checkmark:after {
  border-radius: 50%;
  background: white;
  top: 5px;
  left: 5px;
  width: 20px;
  height: 20px;
}

.css-isurb6 {
  position: absolute;
  width: 100%;
  left: 0px;
  text-align: center;
  top: -22px;
}

.css-s6blyv {
  padding: 0px 14px;
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  border-color: #cfd8dc;
  margin: 0px;
  font-style: normal;
  font-size: 14px;
  font-weight: 500;
  background-color: rgb(225, 245, 254);
}

.css-nm28jr {
  padding: 0px 14px;
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  border-color: #cfd8dc;
  margin: 0px;
  font-style: normal;
  font-size: 14px;
  font-weight: 500;
  background-color: rgb(255, 255, 255);
}

.css-c4ccvv {
  font-family: proxima-soft, sans-serif;
  color: rgb(36, 82, 246);
}

.radio-container--checked .css-s6blyv,
.radio-container--checked .css-nm28jr {
  border-color: #039be5;
}

.radio-container:hover .css-s6blyv,
.radio-container:hover .css-nm28jr {
  border-color: #000000;
}

.legal {
  border: 1px solid #eceff1;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 24px;
  font-size: 15px;
  margin: 32px 0 24px;
}

.legal-divider {
  background: #eceff1;
  height: 1px;
  display: block;
  margin: 24px -8px;
}

.legal-pricing {
  font-size: 16px;
  line-height: 160%;
  position: relative;
}

.legal-pricing .free-period {
  position: absolute;
  right: 20px;
  font-weight: bold;
}

.legal-pricing .paid-period {
  position: absolute;
  right: 0;
}

.legal-terms {
  line-height: 135%;
}

.span-promo-code {
  font-family: proxima-soft;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  text-align: right;
  position: absolute;
  right: 24px;
  top: 28px;
  cursor: pointer;
}

.legal-pricing--promo {
  background: #e1f5fe;
  border-radius: 4px;
}

.promo-period {
  position: absolute;
  right: 0;
  font-weight: bold;
}

.span-promo-code--apply {
  color: #039be5;
}

.span-promo-code--disabled {
  color: #455a64;
  cursor: not-allowed !important;
}

.span-promo-code--remove {
  color: #455a64;
}

.country-combo-box {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    transition: all 0.2s;
    outline: none;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    font-size: 1rem;
    height: auto;
    border: 0;
    background-color: transparent;
    line-height: 1.25;
    color: inherit;
    padding: 1.5rem 2rem 0.75rem 1.6rem;
    cursor: pointer;
    border-radius: 0.25rem;
}

option {
    width: 10px;
}

.country-combo-box-container {
    width: 100%;
    border: 1px solid #cfd8dc;
    border-radius: 0.25rem;
}

.input--text-label-combo {
    position: absolute;
    transition: top 0.2s, font-size 0.2s;
    left: 20px;
    padding: 0;
    height: auto;
    height: initial;
    display: block;
    margin: 0;
    font-size: 10px;
    line-height: 12px;
    align-items: center;
    color: #546e7a;
    top: 8px;
}

.combo-box-arrow {
    position: absolute;
    display: inline-flex;
    width: 1.5rem;
    height: 100%;
    align-items: center;
    justify-content: center;
    right: 0.5rem;
    top: 50%;
    pointer-events: none;
    z-index: 2;
    transform: translateY(-50%);
    color: inherit;
}

.input--text-nameoncard-label {
  position: absolute;
  transition: top 0.2s, font-size 0.2s;
  top: 26px;
  left: 24px;
  color: #a0b1b9;
  font-size: 18px;
  font-family: proxima-nova;
  padding: 0;
  height: auto;
  height: initial;
  display: block;
  margin: 0;
}

.input--text-nameoncard-label-active {
  font-size: 10px;
  line-height: 12px;
  align-items: center;
  color: #546e7a;
  top: 15px;
}

.input--promo-applied {
  background-color: #fafbfb;
}

.InputContainer .InputElement {
  padding-left: 8px;
}

.step-2-new .radio-container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: proxima-soft;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  color: #1c2428;
  border: 1px solid #cfd8dc;
  text-align: center;
  height: 200px;
  width: 100%;
  padding: 0px !important;
  box-sizing: border-box;
  border-radius: 12px;
}

.step-2-new .radio-container:hover {
  border: 1px solid #000000;
}

.step-2-new .radio-container--checked {
  border: 3px solid #039be5 !important;
}

/* Hide the browser's default radio button */
.step-2-new .radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.step-2-new .radio-checkmark {
  position: absolute;
  top: 20px;
  left: 24px;
  height: 32px;
  width: 32px;
  background-color: #ffffff;
  border: 1px solid #cfd8dc;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.step-2-new .radio-container:hover input ~ .radio-checkmark {
  background-color: #ffffff;
}

/* When the radio button is checked, add a blue background */
.step-2-new .radio-container input:checked ~ .radio-checkmark {
  background-color: #ffffff;
  border: 1px solid #cfd8dc;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.step-2-new .radio-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.step-2-new .radio-container input:checked ~ .radio-checkmark:after {
  display: block;
  background: #039be5;
  border: 0.5px solid #cfd8dc;
}

/* Style the indicator (dot/circle) */
.step-2-new .radio-container .radio-checkmark:after {
  border-radius: 50%;
  background: white;
  top: 5px;
  left: 5px;
  width: 20px;
  height: 20px;
}

.check-container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: proxima-nova;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #1c2428;
  margin: 4px 0px 8px;
  border: none;
  padding: 16px 36px;
  height: 80px;
  box-sizing: border-box;
  border-radius: 12px;
}

/* Hide the browser's default check button */
.check-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom check button */
.check-checkmark {
  position: absolute;
  top: 12px;
  left: 0px;
  height: 24px;
  width: 24px;
  background-color: #ffffff;
  border: 1px solid #cfd8dc;
  border-radius: 4px;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.check-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Style the indicator (dot/circle) */
.check-container .check-checkmark:after {
  border-radius: 50%;
  background: white;
  top: 5px;
  left: 5px;
  width: 20px;
  height: 20px;
}

.css-isurb6 {
  position: absolute;
  width: 100%;
  left: 0px;
  text-align: center;
  top: -22px;
}

.css-s6blyv {
  padding: 0px 14px;
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  border-color: #cfd8dc;
  margin: 0px;
  font-style: normal;
  font-size: 14px;
  font-weight: 500;
  background-color: rgb(225, 245, 254);
}

.css-nm28jr {
  padding: 0px 14px;
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  border-color: #cfd8dc;
  margin: 0px;
  font-style: normal;
  font-size: 14px;
  font-weight: 500;
  background-color: rgb(255, 255, 255);
}

.css-c4ccvv {
  font-family: proxima-soft, sans-serif;
  color: rgb(36, 82, 246);
}

.step-2-new .radio-container--checked .css-s6blyv,
.step-2-new .radio-container--checked .css-nm28jr {
  border-color: #039be5;
}

.step-2-new .radio-container:hover .css-s6blyv,
.step-2-new .radio-container:hover .css-nm28jr {
  border-color: #000000;
}

.plans-container {
  display: flex;
  justify-content: space-between;
}

.plan-container {
  display: flex;
  text-align: center;
  width: 50%;
}

.px-s {
  padding-right: 7px;
}

.plan-price {
  margin: 0px;
  font-size: 25px;
}

.plan-interval {
  margin: 0px;
  font-size: 14px;
}

.plan-description {
  margin: 5px 10px 5px;
  color: #1c2428;
  font-size: 14px;
  font-family: "proxima-nova", sans-serif;
  font-weight: 400;
}

.plan-legend {
  margin: 5px 10px 5px;
  color: #465a63;
  font-size: 13px;
  font-family: "proxima-nova", sans-serif;
  font-weight: 400;
}

.header-text {
  font-family: "proxima-soft", sans-serif;
  font-weight: 700;
  font-size: 13px;
  color: white;
  margin: 0px;
}

.plan-header-1 {
  display: flex;
  background-color: #6b9dfd;
  border-top-right-radius: 9px;
  border-top-left-radius: 9px;
  height: 35px;
  margin-bottom: 8px;
  align-items: center;
  justify-content: center;
}

.plan-header-2 {
  display: flex;
  background-color: #9577cb;
  border-top-right-radius: 9px;
  border-top-left-radius: 9px;
  height: 35px;
  margin-bottom: 8px;
  align-items: center;
  justify-content: center;
}

.plan-color-1 {
  color: #6b9dfd;
}

.plan-color-2 {
  color: #9577cb;
}

.section-title {
  margin: 8px !important;
}

.amazon-pay-checkout-container {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 25vh;
}

.fade-in-out {
    width:200px;
    height: 200px;
    -webkit-animation: fadeinout 4s linear forwards infinite;
    animation: fadeinout 4s linear forwards infinite;
}

.amazon-pay-checkout-spinner {
    height:60px;
    width:60px;
    margin-top: 40px;
    -webkit-animation: rotation .6s infinite linear;
    animation: rotation .6s infinite linear;
    border-left:6px solid rgba(0,174,239,.15);
    border-right:6px solid rgba(0,174,239,.15);
    border-bottom:6px solid rgba(0,174,239,.15);
    border-top:6px solid rgba(0,174,239,.8);
    border-radius:100%;
}

@-webkit-keyframes fadeinout {
    0%,100% { opacity: 0.3; }
    50% { opacity: 1; }
}

@keyframes fadeinout {
    0%,100% { opacity: 0.3; }
    50% { opacity: 1; }
}

.amazon-pay-checkout-logo-background {
    margin-left: 20%;
    margin-right: 20%;
    width: 60%;
    height: 60%;
}

.amazon-pay-checkout-status-msg {
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 40px;
    text-align: center;
    font-family: proxima-soft;
    font-size: 32px;
    font-weight: 800;
    color: #1A202C;
    line-height: 110%;
}
.combo-box {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    transition: all 0.2s;
    outline: none;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    font-size: 1rem;
    height: auto;
    border: 0;
    background-color: transparent;
    line-height: 1.25;
    color: inherit;
    padding: 1.5rem 2rem 0.75rem 1.6rem;
    cursor: pointer;
    border-radius: 0.25rem;
}

option {
    width: 10px;
}

.combo-box-container {
    width: 100%;
    border: 1px solid #cfd8dc;
    border-radius: 0.25rem;
}

.input--text-label-combo {
    position: absolute;
    transition: top 0.2s, font-size 0.2s;
    left: 20px;
    padding: 0;
    height: auto;
    height: initial;
    display: block;
    margin: 0;
    font-size: 10px;
    line-height: 12px;
    align-items: center;
    color: #546e7a;
    top: 8px;
}

.combo-box-arrow {
    position: absolute;
    display: inline-flex;
    width: 1.5rem;
    height: 100%;
    align-items: center;
    justify-content: center;
    right: 0.5rem;
    top: 50%;
    pointer-events: none;
    z-index: 2;
    transform: translateY(-50%);
    color: inherit;
}

span.terms {
  border: 1px solid #eceff1;
  box-sizing: border-box;
  border-radius: 4px;
  display: block;
  width: 100%;
  padding: 20px 25px;
  text-align: center;
  margin: 24px 0;
}

span.terms a {
  color: #1c2428;
  -webkit-text-decoration-color: #1c2428;
          text-decoration-color: #1c2428;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: white;
  border: 1px solid #cfd8dc;
  box-sizing: border-box;
  border-radius: 4px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-container {
  margin: 11px 0 -4px;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-container input:focus ~ .checkmark {
  border-color: #546e7a;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  width: 12px;
  height: 12px;
  background: #039be5;
  border-radius: 2px;
}

.rating-image {
  max-width: 344px;
  margin-top: -26px;
  margin-bottom: 20px;
}

.input--text-nameoncard-label {
  position: absolute;
  transition: top 0.2s, font-size 0.2s;
  top: 26px;
  left: 24px;
  color: #a0b1b9;
  font-size: 18px;
  font-family: proxima-nova;
  padding: 0;
  height: auto;
  height: initial;
  display: block;
  margin: 0;
}

.input--text-nameoncard-label-active {
  font-size: 10px;
  line-height: 12px;
  align-items: center;
  color: #546e7a;
  top: 15px;
}

.input--promo-applied {
  background-color: #fafbfb;
}

.InputContainer .InputElement {
  padding-left: 8px;
}

.radio-container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: proxima-soft;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  color: #1c2428;
  margin: 4px 0px 8px;
  border: 1px solid #cfd8dc;
  padding: 18px 74px;
  height: 80px;
  box-sizing: border-box;
  border-radius: 12px;
}

.radio-container:hover {
  border: 1px solid #000000;
}

.radio-container--checked {
  border: 1px solid #039be5;
}

.radio-container--yearly {
  margin-top: 24px;
}

/* Hide the browser's default radio button */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.radio-checkmark {
  position: absolute;
  top: 20px;
  left: 24px;
  height: 32px;
  width: 32px;
  background-color: #ffffff;
  border: 1px solid #cfd8dc;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radio-container:hover input ~ .radio-checkmark {
  background-color: #ffffff;
}

/* When the radio button is checked, add a blue background */
.radio-container input:checked ~ .radio-checkmark {
  background-color: #ffffff;
  border: 1px solid #cfd8dc;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radio-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .radio-checkmark:after {
  display: block;
  background: #039be5;
  border: 0.5px solid #cfd8dc;
}

/* Style the indicator (dot/circle) */
.radio-container .radio-checkmark:after {
  border-radius: 50%;
  background: white;
  top: 5px;
  left: 5px;
  width: 20px;
  height: 20px;
}

.check-container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: proxima-nova;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #1c2428;
  margin: 4px 0px 8px;
  border: none;
  padding: 16px 36px;
  height: 80px;
  box-sizing: border-box;
  border-radius: 12px;
}

/* Hide the browser's default check button */
.check-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom check button */
.check-checkmark {
  position: absolute;
  top: 12px;
  left: 0px;
  height: 24px;
  width: 24px;
  background-color: #ffffff;
  border: 1px solid #cfd8dc;
  border-radius: 4px;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.check-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Style the indicator (dot/circle) */
.check-container .check-checkmark:after {
  border-radius: 50%;
  background: white;
  top: 5px;
  left: 5px;
  width: 20px;
  height: 20px;
}

.css-isurb6 {
  position: absolute;
  width: 100%;
  left: 0px;
  text-align: center;
  top: -22px;
}

.css-s6blyv {
  padding: 0px 14px;
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  border-color: #cfd8dc;
  margin: 0px;
  font-style: normal;
  font-size: 14px;
  font-weight: 500;
  background-color: rgb(225, 245, 254);
}

.css-nm28jr {
  padding: 0px 14px;
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  border-color: #cfd8dc;
  margin: 0px;
  font-style: normal;
  font-size: 14px;
  font-weight: 500;
  background-color: rgb(255, 255, 255);
}

.css-c4ccvv {
  font-family: proxima-soft, sans-serif;
  color: rgb(36, 82, 246);
}

.radio-container--checked .css-s6blyv,
.radio-container--checked .css-nm28jr {
  border-color: #039be5;
}

.radio-container:hover .css-s6blyv,
.radio-container:hover .css-nm28jr {
  border-color: #000000;
}

.legal {
  border: 1px solid #eceff1;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 24px;
  font-size: 15px;
  margin: 32px 0 24px;
}

.legal-divider {
  background: #eceff1;
  height: 1px;
  display: block;
  margin: 24px -8px;
}

.legal-pricing {
  font-size: 16px;
  line-height: 160%;
  position: relative;
}

.legal-pricing .free-period {
  position: absolute;
  right: 20px;
  font-weight: bold;
}

.legal-pricing .paid-period {
  position: absolute;
  right: 0;
}

.legal-terms {
  line-height: 135%;
}

.span-promo-code {
  font-family: proxima-soft;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  text-align: right;
  position: absolute;
  right: 24px;
  top: 28px;
  cursor: pointer;
}

.legal-pricing--promo {
  background: #e1f5fe;
  border-radius: 4px;
}

.promo-period {
  position: absolute;
  right: 0;
  font-weight: bold;
}

.span-promo-code--apply {
  color: #039be5;
}

.span-promo-code--disabled {
  color: #455a64;
  cursor: not-allowed !important;
}

.span-promo-code--remove {
  color: #455a64;
}

.combo-box {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    transition: all 0.2s;
    outline: none;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    font-size: 1rem;
    height: auto;
    border: 0;
    background-color: transparent;
    line-height: 1.25;
    color: inherit;
    padding: 1.5rem 2rem 0.75rem 1.6rem;
    cursor: pointer;
    border-radius: 0.25rem;
}

option {
    width: 10px;
}

.combo-box-container {
    width: 100%;
    border: 1px solid #cfd8dc;
    border-radius: 0.25rem;
}

.input--text-label-combo {
    position: absolute;
    transition: top 0.2s, font-size 0.2s;
    left: 20px;
    padding: 0;
    height: auto;
    height: initial;
    display: block;
    margin: 0;
    font-size: 10px;
    line-height: 12px;
    align-items: center;
    color: #546e7a;
    top: 8px;
}

.combo-box-arrow {
    position: absolute;
    display: inline-flex;
    width: 1.5rem;
    height: 100%;
    align-items: center;
    justify-content: center;
    right: 0.5rem;
    top: 50%;
    pointer-events: none;
    z-index: 2;
    transform: translateY(-50%);
    color: inherit;
}

span.terms {
  border: 1px solid #eceff1;
  box-sizing: border-box;
  border-radius: 4px;
  display: block;
  width: 100%;
  padding: 20px 25px;
  text-align: center;
  margin: 24px 0;
}

span.terms a {
  color: #1c2428;
  -webkit-text-decoration-color: #1c2428;
          text-decoration-color: #1c2428;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: white;
  border: 1px solid #cfd8dc;
  box-sizing: border-box;
  border-radius: 4px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-container {
  margin: 11px 0 -4px;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-container input:focus ~ .checkmark {
  border-color: #546e7a;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  width: 12px;
  height: 12px;
  background: #039be5;
  border-radius: 2px;
}

.rating-image {
  max-width: 344px;
  margin-top: -26px;
  margin-bottom: 20px;
}

.input--text-nameoncard-label {
  position: absolute;
  transition: top 0.2s, font-size 0.2s;
  top: 26px;
  left: 24px;
  color: #a0b1b9;
  font-size: 18px;
  font-family: proxima-nova;
  padding: 0;
  height: auto;
  height: initial;
  display: block;
  margin: 0;
}

.input--text-nameoncard-label-active {
  font-size: 10px;
  line-height: 12px;
  align-items: center;
  color: #546e7a;
  top: 15px;
}

.input--promo-applied {
  background-color: #fafbfb;
}

.InputContainer .InputElement {
  padding-left: 8px;
}

.radio-container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: proxima-soft;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  color: #1c2428;
  margin: 4px 0px 8px;
  border: 1px solid #cfd8dc;
  padding: 18px 74px;
  height: 80px;
  box-sizing: border-box;
  border-radius: 12px;
}

.radio-container:hover {
  border: 1px solid #000000;
}

.radio-container--checked {
  border: 1px solid #039be5;
}

.radio-container--yearly {
  margin-top: 24px;
}

/* Hide the browser's default radio button */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.radio-checkmark {
  position: absolute;
  top: 20px;
  left: 24px;
  height: 32px;
  width: 32px;
  background-color: #ffffff;
  border: 1px solid #cfd8dc;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radio-container:hover input ~ .radio-checkmark {
  background-color: #ffffff;
}

/* When the radio button is checked, add a blue background */
.radio-container input:checked ~ .radio-checkmark {
  background-color: #ffffff;
  border: 1px solid #cfd8dc;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radio-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .radio-checkmark:after {
  display: block;
  background: #039be5;
  border: 0.5px solid #cfd8dc;
}

/* Style the indicator (dot/circle) */
.radio-container .radio-checkmark:after {
  border-radius: 50%;
  background: white;
  top: 5px;
  left: 5px;
  width: 20px;
  height: 20px;
}

.check-container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: proxima-nova;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #1c2428;
  margin: 4px 0px 8px;
  border: none;
  padding: 16px 36px;
  height: 80px;
  box-sizing: border-box;
  border-radius: 12px;
}

/* Hide the browser's default check button */
.check-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom check button */
.check-checkmark {
  position: absolute;
  top: 12px;
  left: 0px;
  height: 24px;
  width: 24px;
  background-color: #ffffff;
  border: 1px solid #cfd8dc;
  border-radius: 4px;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.check-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Style the indicator (dot/circle) */
.check-container .check-checkmark:after {
  border-radius: 50%;
  background: white;
  top: 5px;
  left: 5px;
  width: 20px;
  height: 20px;
}

.css-isurb6 {
  position: absolute;
  width: 100%;
  left: 0px;
  text-align: center;
  top: -22px;
}

.css-s6blyv {
  padding: 0px 14px;
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  border-color: #cfd8dc;
  margin: 0px;
  font-style: normal;
  font-size: 14px;
  font-weight: 500;
  background-color: rgb(225, 245, 254);
}

.css-nm28jr {
  padding: 0px 14px;
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  border-color: #cfd8dc;
  margin: 0px;
  font-style: normal;
  font-size: 14px;
  font-weight: 500;
  background-color: rgb(255, 255, 255);
}

.css-c4ccvv {
  font-family: proxima-soft, sans-serif;
  color: rgb(36, 82, 246);
}

.radio-container--checked .css-s6blyv,
.radio-container--checked .css-nm28jr {
  border-color: #039be5;
}

.radio-container:hover .css-s6blyv,
.radio-container:hover .css-nm28jr {
  border-color: #000000;
}

.legal {
  border: 1px solid #eceff1;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 24px;
  font-size: 15px;
  margin: 32px 0 24px;
}

.legal-divider {
  background: #eceff1;
  height: 1px;
  display: block;
  margin: 24px -8px;
}

.legal-pricing {
  font-size: 16px;
  line-height: 160%;
  position: relative;
}

.legal-pricing .free-period {
  position: absolute;
  right: 20px;
  font-weight: bold;
}

.legal-pricing .paid-period {
  position: absolute;
  right: 0;
}

.legal-terms {
  line-height: 135%;
}

.span-promo-code {
  font-family: proxima-soft;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  text-align: right;
  position: absolute;
  right: 24px;
  top: 28px;
  cursor: pointer;
}

.legal-pricing--promo {
  background: #e1f5fe;
  border-radius: 4px;
}

.promo-period {
  position: absolute;
  right: 0;
  font-weight: bold;
}

.span-promo-code--apply {
  color: #039be5;
}

.span-promo-code--disabled {
  color: #455a64;
  cursor: not-allowed !important;
}

.span-promo-code--remove {
  color: #455a64;
}

span.terms {
  border: 1px solid #eceff1;
  box-sizing: border-box;
  border-radius: 4px;
  display: block;
  width: 100%;
  padding: 20px 25px;
  text-align: center;
  margin: 24px 0;
}

span.terms a {
  color: #1c2428;
  -webkit-text-decoration-color: #1c2428;
          text-decoration-color: #1c2428;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: white;
  border: 1px solid #cfd8dc;
  box-sizing: border-box;
  border-radius: 4px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-container {
  margin: 11px 0 -4px;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-container input:focus ~ .checkmark {
  border-color: #546e7a;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  width: 12px;
  height: 12px;
  background: #039be5;
  border-radius: 2px;
}

.gift-vooks {
  margin-top: 30px;
}

.gift-vooks-mobile {
  margin-top: 20px;
  margin-bottom: 20px;
  display: none;
}

.gift-vooks:hover {
  text-decoration: underline;
  cursor: pointer;
}

div.gift-vooks img {
  margin-right: 10px;
  vertical-align: top;
}

div.gift-vooks-mobile img {
  margin-right: 10px;
  vertical-align: top;
}

textarea.input--text {
  border: 1px solid rgb(207, 216, 220);
  box-sizing: border-box;
  border-radius: 4px;
  outline: none;
  margin: 4px 0px;
  padding: 10px;
}

span.gift-subtitle {
  margin: 16px 0;
  display: block;
  font-weight: 600;
  font-family: proxima-soft;
}

.rating-image {
  max-width: 344px;
  margin-top: -26px;
  margin-bottom: 20px;
}

.input--button__help-text {
  text-align: center;
  display: block;
}

.trust-pilot-image {
  display: block;
  width: 45%;
  height: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

@media screen and (max-width: 770px) and (max-height: 1030px) and (orientation:portrait), screen and (max-height: 770px) and (max-width: 1030px) and (orientation:landscape) {
  div.hero {
    margin: 0 auto 10px !important;
  }
}



.StripeElement--complete ~ button.stripe-submit {
  background: #2452F6;
  color: #ffffff;
}

.legal {
  border: 1px solid #ECEFF1;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 24px;
  font-size: 15px;
  margin: 32px 0 24px;
}

.legal-divider {
  background: #ECEFF1;
  height: 1px;
  display: block;
  margin: 24px -8px;
}

.legal-pricing {
  font-size: 16px;
  line-height: 160%;
  position: relative;
}

.legal-pricing .free-period {
  position: absolute;
  right: 20px;
  font-weight: bold;
}

.legal-pricing .paid-period {
  position: absolute;
  right: 0;
}

.legal-terms {
  line-height: 135%;
}

.span-promo-code {
  font-family: proxima-soft;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  text-align: right;
  position: absolute;
  right: 24px;
  top: 28px;
  cursor: pointer;
}

.legal-pricing--promo {
  background: #E1F5FE;
  border-radius: 4px;
}

.promo-period {
  position: absolute;
  right: 0;
  font-weight: bold;
}

.span-promo-code--apply {
  color: #039BE5;
}

.span-promo-code--disabled {
  color: #455a64;
  cursor: not-allowed !important;
}

.span-promo-code--remove {
  color: #455A64;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.vertical-panel {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.common-checkout-btn {
  color: white;
  font-size: 15px;
  background: #1C2428;
  border: none;
  border-radius: 100px;
  font-family: proxima-soft;
  font-weight: 800;
  font-size: 18px;
  cursor: pointer;
  width: 100%;
  height: 56px;
}

.amazon-checkout-btn {
  margin: 20px 0;
  height: 60px;
  width: 100%;
}

.amazon-checkout-btn-hidden {
  display: none;
}

.change-payment-method-btn {
  color: white;
  font-size: 15px;
  background: #1C2428;
  border: none;
  border-radius: 100px;
  font-family: proxima-soft;
  font-weight: 800;
  font-size: 18px;
  cursor: pointer;
  width: 100%;
  height: 50px;
}

.payment-method-selection-divider{
  margin-top: 10px;
  margin-bottom: 10px;
}

.input--text-nameoncard-label {
  position: absolute;
  transition: top .2s, font-size .2s;
  top: 26px;
  left: 24px;
  color: #A0B1B9;
  font-size: 18px;
  font-family: proxima-nova;
  padding: 0;
  height: auto;
  height: initial;
  display: block;
  margin: 0;
}

.input--text-nameoncard-label-active {
  font-size: 10px;
  line-height: 12px;
  align-items: center;
  color: #546E7A;
  top: 15px;
}

.input--promo-applied {
  background-color: #FAFBFB;
}

.InputContainer .InputElement {
  padding-left: 8px;
}

.radio-container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: proxima-soft;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  color: #1C2428;
  margin: 4px 0px 8px;
  border: 1px solid #CFD8DC;
  padding: 18px 74px;
  height: 80px;
  box-sizing: border-box;
  border-radius: 12px;
}

.radio-container:hover {
  border: 1px solid #000000;
}

.radio-container--checked {
  border: 1px solid #039BE5;
}

.radio-container--yearly {
  margin-top: 24px;
}

/* Hide the browser's default radio button */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.radio-checkmark {
  position: absolute;
  top: 20px;
  left: 24px;
  height: 32px;
  width: 32px;
  background-color: #ffffff;
  border: 1px solid #CFD8DC;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radio-container:hover input ~ .radio-checkmark {
  background-color: #ffffff;
}

/* When the radio button is checked, add a blue background */
.radio-container input:checked ~ .radio-checkmark {
  background-color: #ffffff;
  border: 1px solid #CFD8DC;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radio-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .radio-checkmark:after {
  display: block;
  background: #039BE5;
  border: 0.5px solid #CFD8DC;
}

/* Style the indicator (dot/circle) */
.radio-container .radio-checkmark:after {
  border-radius: 50%;
  background: white;
  top: 5px;
  left: 5px;
  width: 20px;
  height: 20px;
}


.check-container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: proxima-nova;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #1C2428;
  margin: 4px 0px 8px;
  border: none;
  padding: 16px 36px;
  height: 80px;
  box-sizing: border-box;
  border-radius: 12px;
}

/* Hide the browser's default check button */
.check-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom check button */
.check-checkmark {
  position: absolute;
  top: 12px;
  left: 0px;
  height: 24px;
  width: 24px;
  background-color: #ffffff;
  border: 1px solid #CFD8DC;
  border-radius: 4px;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.check-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Style the indicator (dot/circle) */
.check-container .check-checkmark:after {
  border-radius: 50%;
  background: white;
  top: 5px;
  left: 5px;
  width: 20px;
  height: 20px;
}

.css-isurb6 {
  position: absolute;
  width: 100%;
  left: 0px;
  text-align: center;
  top: -22px;
}

.css-s6blyv {
  padding: 0px 14px;
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  border-color: #CFD8DC;
  margin: 0px;
  font-style: normal;
  font-size: 14px;
  font-weight: 500;
  background-color: rgb(225, 245, 254);
}

.css-nm28jr {
  padding: 0px 14px;
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  border-color: #CFD8DC;
  margin: 0px;
  font-style: normal;
  font-size: 14px;
  font-weight: 500;
  background-color: rgb(255, 255, 255);
}

.css-c4ccvv {
  font-family: proxima-soft, sans-serif;
  color: rgb(36, 82, 246);
}

.radio-container--checked .css-s6blyv, .radio-container--checked .css-nm28jr {
  border-color: #039BE5;
}

.radio-container:hover .css-s6blyv, .radio-container:hover .css-nm28jr {
  border-color: #000000;
}

.form {
  margin-top: 20px;
  margin-bottom: 20px;
}

.topic-divider {
  border-top: 1px solid #b4c4cd;
}


