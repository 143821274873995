.input--text-nameoncard-label {
  position: absolute;
  transition: top 0.2s, font-size 0.2s;
  top: 26px;
  left: 24px;
  color: #a0b1b9;
  font-size: 18px;
  font-family: proxima-nova;
  padding: 0;
  height: initial;
  display: block;
  margin: 0;
}

.input--text-nameoncard-label-active {
  font-size: 10px;
  line-height: 12px;
  align-items: center;
  color: #546e7a;
  top: 15px;
}

.input--promo-applied {
  background-color: #fafbfb;
}

.InputContainer .InputElement {
  padding-left: 8px;
}

.step-2-new .radio-container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: proxima-soft;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  color: #1c2428;
  border: 1px solid #cfd8dc;
  text-align: center;
  height: 200px;
  width: 100%;
  padding: 0px !important;
  box-sizing: border-box;
  border-radius: 12px;
}

.step-2-new .radio-container:hover {
  border: 1px solid #000000;
}

.step-2-new .radio-container--checked {
  border: 3px solid #039be5 !important;
}

/* Hide the browser's default radio button */
.step-2-new .radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.step-2-new .radio-checkmark {
  position: absolute;
  top: 20px;
  left: 24px;
  height: 32px;
  width: 32px;
  background-color: #ffffff;
  border: 1px solid #cfd8dc;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.step-2-new .radio-container:hover input ~ .radio-checkmark {
  background-color: #ffffff;
}

/* When the radio button is checked, add a blue background */
.step-2-new .radio-container input:checked ~ .radio-checkmark {
  background-color: #ffffff;
  border: 1px solid #cfd8dc;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.step-2-new .radio-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.step-2-new .radio-container input:checked ~ .radio-checkmark:after {
  display: block;
  background: #039be5;
  border: 0.5px solid #cfd8dc;
}

/* Style the indicator (dot/circle) */
.step-2-new .radio-container .radio-checkmark:after {
  border-radius: 50%;
  background: white;
  top: 5px;
  left: 5px;
  width: 20px;
  height: 20px;
}

.check-container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: proxima-nova;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #1c2428;
  margin: 4px 0px 8px;
  border: none;
  padding: 16px 36px;
  height: 80px;
  box-sizing: border-box;
  border-radius: 12px;
}

/* Hide the browser's default check button */
.check-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom check button */
.check-checkmark {
  position: absolute;
  top: 12px;
  left: 0px;
  height: 24px;
  width: 24px;
  background-color: #ffffff;
  border: 1px solid #cfd8dc;
  border-radius: 4px;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.check-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Style the indicator (dot/circle) */
.check-container .check-checkmark:after {
  border-radius: 50%;
  background: white;
  top: 5px;
  left: 5px;
  width: 20px;
  height: 20px;
}

.css-isurb6 {
  position: absolute;
  width: 100%;
  left: 0px;
  text-align: center;
  top: -22px;
}

.css-s6blyv {
  padding: 0px 14px;
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  border-color: #cfd8dc;
  margin: 0px;
  font-style: normal;
  font-size: 14px;
  font-weight: 500;
  background-color: rgb(225, 245, 254);
}

.css-nm28jr {
  padding: 0px 14px;
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  border-color: #cfd8dc;
  margin: 0px;
  font-style: normal;
  font-size: 14px;
  font-weight: 500;
  background-color: rgb(255, 255, 255);
}

.css-c4ccvv {
  font-family: proxima-soft, sans-serif;
  color: rgb(36, 82, 246);
}

.step-2-new .radio-container--checked .css-s6blyv,
.step-2-new .radio-container--checked .css-nm28jr {
  border-color: #039be5;
}

.step-2-new .radio-container:hover .css-s6blyv,
.step-2-new .radio-container:hover .css-nm28jr {
  border-color: #000000;
}

.plans-container {
  display: flex;
  justify-content: space-between;
}

.plan-container {
  display: flex;
  text-align: center;
  width: 50%;
}

.px-s {
  padding-right: 7px;
}

.plan-price {
  margin: 0px;
  font-size: 25px;
}

.plan-interval {
  margin: 0px;
  font-size: 14px;
}

.plan-description {
  margin: 5px 10px 5px;
  color: #1c2428;
  font-size: 14px;
  font-family: "proxima-nova", sans-serif;
  font-weight: 400;
}

.plan-legend {
  margin: 5px 10px 5px;
  color: #465a63;
  font-size: 13px;
  font-family: "proxima-nova", sans-serif;
  font-weight: 400;
}

.header-text {
  font-family: "proxima-soft", sans-serif;
  font-weight: 700;
  font-size: 13px;
  color: white;
  margin: 0px;
}

.plan-header-1 {
  display: flex;
  background-color: #6b9dfd;
  border-top-right-radius: 9px;
  border-top-left-radius: 9px;
  height: 35px;
  margin-bottom: 8px;
  align-items: center;
  justify-content: center;
}

.plan-header-2 {
  display: flex;
  background-color: #9577cb;
  border-top-right-radius: 9px;
  border-top-left-radius: 9px;
  height: 35px;
  margin-bottom: 8px;
  align-items: center;
  justify-content: center;
}

.plan-color-1 {
  color: #6b9dfd;
}

.plan-color-2 {
  color: #9577cb;
}

.section-title {
  margin: 8px !important;
}
