.StripeElement--complete ~ button.stripe-submit {
  background: #2452F6;
  color: #ffffff;
}

.legal {
  border: 1px solid #ECEFF1;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 24px;
  font-size: 15px;
  margin: 32px 0 24px;
}

.legal-divider {
  background: #ECEFF1;
  height: 1px;
  display: block;
  margin: 24px -8px;
}

.legal-pricing {
  font-size: 16px;
  line-height: 160%;
  position: relative;
}

.legal-pricing .free-period {
  position: absolute;
  right: 20px;
  font-weight: bold;
}

.legal-pricing .paid-period {
  position: absolute;
  right: 0;
}

.legal-terms {
  line-height: 135%;
}

.span-promo-code {
  font-family: proxima-soft;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  text-align: right;
  position: absolute;
  right: 24px;
  top: 28px;
  cursor: pointer;
}

.legal-pricing--promo {
  background: #E1F5FE;
  border-radius: 4px;
}

.promo-period {
  position: absolute;
  right: 0;
  font-weight: bold;
}

.span-promo-code--apply {
  color: #039BE5;
}

.span-promo-code--disabled {
  color: #455a64;
  cursor: not-allowed !important;
}

.span-promo-code--remove {
  color: #455A64;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.vertical-panel {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.common-checkout-btn {
  color: white;
  font-size: 15px;
  background: #1C2428;
  border: none;
  border-radius: 100px;
  font-family: proxima-soft;
  font-weight: 800;
  font-size: 18px;
  cursor: pointer;
  width: 100%;
  height: 56px;
}

.amazon-checkout-btn {
  margin: 20px 0;
  height: 60px;
  width: 100%;
}

.amazon-checkout-btn-hidden {
  display: none;
}

.change-payment-method-btn {
  color: white;
  font-size: 15px;
  background: #1C2428;
  border: none;
  border-radius: 100px;
  font-family: proxima-soft;
  font-weight: 800;
  font-size: 18px;
  cursor: pointer;
  width: 100%;
  height: 50px;
}

.payment-method-selection-divider{
  margin-top: 10px;
  margin-bottom: 10px;
}
