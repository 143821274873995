.input--text-nameoncard-label {
  position: absolute;
  transition: top .2s, font-size .2s;
  top: 26px;
  left: 24px;
  color: #A0B1B9;
  font-size: 18px;
  font-family: proxima-nova;
  padding: 0;
  height: initial;
  display: block;
  margin: 0;
}

.input--text-nameoncard-label-active {
  font-size: 10px;
  line-height: 12px;
  align-items: center;
  color: #546E7A;
  top: 15px;
}

.input--promo-applied {
  background-color: #FAFBFB;
}

.InputContainer .InputElement {
  padding-left: 8px;
}

.radio-container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: proxima-soft;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  color: #1C2428;
  margin: 4px 0px 8px;
  border: 1px solid #CFD8DC;
  padding: 18px 74px;
  height: 80px;
  box-sizing: border-box;
  border-radius: 12px;
}

.radio-container:hover {
  border: 1px solid #000000;
}

.radio-container--checked {
  border: 1px solid #039BE5;
}

.radio-container--yearly {
  margin-top: 24px;
}

/* Hide the browser's default radio button */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.radio-checkmark {
  position: absolute;
  top: 20px;
  left: 24px;
  height: 32px;
  width: 32px;
  background-color: #ffffff;
  border: 1px solid #CFD8DC;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radio-container:hover input ~ .radio-checkmark {
  background-color: #ffffff;
}

/* When the radio button is checked, add a blue background */
.radio-container input:checked ~ .radio-checkmark {
  background-color: #ffffff;
  border: 1px solid #CFD8DC;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radio-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .radio-checkmark:after {
  display: block;
  background: #039BE5;
  border: 0.5px solid #CFD8DC;
}

/* Style the indicator (dot/circle) */
.radio-container .radio-checkmark:after {
  border-radius: 50%;
  background: white;
  top: 5px;
  left: 5px;
  width: 20px;
  height: 20px;
}


.check-container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: proxima-nova;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #1C2428;
  margin: 4px 0px 8px;
  border: none;
  padding: 16px 36px;
  height: 80px;
  box-sizing: border-box;
  border-radius: 12px;
}

/* Hide the browser's default check button */
.check-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom check button */
.check-checkmark {
  position: absolute;
  top: 12px;
  left: 0px;
  height: 24px;
  width: 24px;
  background-color: #ffffff;
  border: 1px solid #CFD8DC;
  border-radius: 4px;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.check-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Style the indicator (dot/circle) */
.check-container .check-checkmark:after {
  border-radius: 50%;
  background: white;
  top: 5px;
  left: 5px;
  width: 20px;
  height: 20px;
}

.css-isurb6 {
  position: absolute;
  width: 100%;
  left: 0px;
  text-align: center;
  top: -22px;
}

.css-s6blyv {
  padding: 0px 14px;
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  border-color: #CFD8DC;
  margin: 0px;
  font-style: normal;
  font-size: 14px;
  font-weight: 500;
  background-color: rgb(225, 245, 254);
}

.css-nm28jr {
  padding: 0px 14px;
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  border-color: #CFD8DC;
  margin: 0px;
  font-style: normal;
  font-size: 14px;
  font-weight: 500;
  background-color: rgb(255, 255, 255);
}

.css-c4ccvv {
  font-family: proxima-soft, sans-serif;
  color: rgb(36, 82, 246);
}

.radio-container--checked .css-s6blyv, .radio-container--checked .css-nm28jr {
  border-color: #039BE5;
}

.radio-container:hover .css-s6blyv, .radio-container:hover .css-nm28jr {
  border-color: #000000;
}

.form {
  margin-top: 20px;
  margin-bottom: 20px;
}

.topic-divider {
  border-top: 1px solid #b4c4cd;
}

