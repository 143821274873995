.input--text {
  height: 56px;
  font-size: 16px;
  padding: 20px 24px 8px 24px;
  font-family: proxima-nova;
  width: 100%;
  margin: 4px 0px;
  resize: vertical;
}

.input--nameoncard {
  margin: 8px 0;
}

.input--text:focus ~ label {
  font-size: 10px;
  line-height: 12px;
  align-items: center;
  color: #546e7a;
  top: 13px;
}

.input-with-label {
  position: relative;
}

.input--text-label {
  position: absolute;
  transition: top 0.2s, font-size 0.2s;
  top: 21px;
  left: 29px;
  color: #a0b1b9;
  font-size: 18px;
  font-family: proxima-nova;
  padding: 0;
  height: initial;
  display: block;
  margin: 0;
}

.input--text-label-active {
  font-size: 10px;
  line-height: 12px;
  align-items: center;
  color: #546e7a;
  top: 13px;
}

.input--checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

div.step {
  text-align: left;
  max-width: 343px;
  margin: 10px auto;
}

span.step-time {
  font-size: 14px;
}

span.step-savings {
  background: #e1f5fe;
  border-radius: 100px;
  font-size: 12px;
  position: absolute;
  right: 24px;
  top: 26px;
  padding: 4px 12px;
  color: #01579b;
}

span.step-no-charge {
  background: #eceff1;
  border-radius: 4px;
  font-size: 15px;
  line-height: 130%;
  display: block;
  font-weight: bold;
  padding: 16px;
  text-align: center;
  margin: 24px 0 16px 0;
}

.input--button {
  color: #ffffff;
  background: #2452f6;
  border-radius: 100px;
  font-family: proxima-soft;
  font-weight: 800;
  font-size: 18px;
  text-align: center;
  height: 56px;
  width: 100%;
  border: none;
  outline: none;
  cursor: pointer;
}

.input--button.disabled,
.input--button_secondary.disabled {
  pointer-events: none;
  opacity: 0.7;
}

.input--button_secondary {
  color: #1c2428;
  background: #ffffff;
  border: 1px solid #cfd8dc;
  border-radius: 100px;
  font-family: proxima-soft;
  font-weight: 800;
  font-size: 18px;
  text-align: center;
  height: 56px;
  width: 100%;
  outline: none;
  cursor: pointer;
}

.steps-container {
  padding-bottom: 16px;
  font-family: proxima-soft, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: 100%;
  text-rendering: optimizelegibility;
  line-height: 1.5;
  box-sizing: border-box;
  border-width: 0px;
  border-style: solid;
  border-color: rgb(226, 232, 240);
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  color: rgb(28, 36, 40);
  display: flex;
  flex-direction: column;
  margin: 0 auto 2rem;
  max-width: 32rem;
  padding: 1rem 1.5rem 2rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  width: 100%;
}

.step h2 {
  margin-bottom: 0px;
  font-weight: 800;
  font-size: 1.3rem;
}

.link {
  margin-top: 5px;
  display: flex;
  justify-content: center;
}

.link a {
  color: #1c2428;
  text-decoration-color: #1c2428;
}
