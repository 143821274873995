.pricing-summary {
  border: 1px solid #ECEFF1;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 24px;
  font-size: 16px;
  margin: 32px 0 24px;
}

.pricing-summary__subscription {
  text-transform: capitalize;
}

.pricing-summary__subscription--price {
  float: right;
}

.pricing-summary__total--price {
  float: right;
  font-weight: 800;
}

.pricing-summary--divider {
  background: #ECEFF1;
  height: 1px;
  display: block;
  margin-top: 16px;
  margin-bottom: 16px;
}

.legal-pricing {
  font-size: 16px;
  line-height: 160%;
  position: relative;
}

.amazon-pay-logo {
  width: 90px;
  height: 26px;
}

.step3__customer_exists {
  text-align: center;
}

.step3__customer_exists span {
  margin-bottom: 10px;
  display: block;
}
