.amazon-pay-checkout-container {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 25vh;
}

.fade-in-out {
    width:200px;
    height: 200px;
    -webkit-animation: fadeinout 4s linear forwards infinite;
    animation: fadeinout 4s linear forwards infinite;
}

.amazon-pay-checkout-spinner {
    height:60px;
    width:60px;
    margin-top: 40px;
    -webkit-animation: rotation .6s infinite linear;
    -moz-animation: rotation .6s infinite linear;
    -o-animation: rotation .6s infinite linear;
    animation: rotation .6s infinite linear;
    border-left:6px solid rgba(0,174,239,.15);
    border-right:6px solid rgba(0,174,239,.15);
    border-bottom:6px solid rgba(0,174,239,.15);
    border-top:6px solid rgba(0,174,239,.8);
    border-radius:100%;
}

@-webkit-keyframes fadeinout {
    0%,100% { opacity: 0.3; }
    50% { opacity: 1; }
}

@keyframes fadeinout {
    0%,100% { opacity: 0.3; }
    50% { opacity: 1; }
}

.amazon-pay-checkout-logo-background {
    margin-left: 20%;
    margin-right: 20%;
    width: 60%;
    height: 60%;
}

.amazon-pay-checkout-status-msg {
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 40px;
    text-align: center;
    font-family: proxima-soft;
    font-size: 32px;
    font-weight: 800;
    color: #1A202C;
    line-height: 110%;
}