.App {
  font-family: proxima-nova;
  color: #1C2428;
  font-size: 16px;
  text-align: center;
  padding: 16px;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

header {
  position: relative;
  padding-bottom: 10px;
  text-align: left;
}

.logo {
  cursor: pointer;
}

.login-link {
  text-decoration: none;
  font-family: proxima-soft;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 10px;
  color: #1C2428;
  margin: 16px 0px;
}

h1 {
  font-family: proxima-soft;
  font-size: 32px;
  font-weight: 800;
  margin: 8px 8px;
  line-height: 110%;
}

h2 {
  font-family: proxima-soft;
  font-size: 18px;
  font-weight: bold;
  margin: 8px 0 28px;
}

input {
  border: 1px solid #CFD8DC;
  box-sizing: border-box;
  border-radius: 4px;
  outline: none;
  -webkit-appearance: none;
}

input:focus {
  border-color: #546E7A;
}

div.hero {
  max-width: 367px;
  margin: 0 auto 40px;
}

.alert {
  background: #D03B35;
  border-radius: 12px;
  padding: 12px 40px 12px 24px;
  color: #ffffff;
  font-weight: 600;
  position: fixed;
  z-index: 1;
  top: 10px;
  left: max(8px, calc((100% - 492px)/2));
  width: calc(100% - 80px);
  max-width: 452px;
}

@media(max-width: 700px) {
  .alert {
    border-radius: initial;
    top: 0;
    left: 0;
    right: 0;
    width: auto;
    max-width: initial;
  }
}

.alert-dismiss {
  border: none;
  background: none;
  right: 10px;
  top: 8px;
  position: absolute;
}

.alert-dismiss:focus {
  outline: none;
}

.spinner {
   position: fixed;
   left: calc(50% - 30px);
   top: calc(50% - 30px);
   height:60px;
   width:60px;
   margin:0px auto;
   -webkit-animation: rotation .6s infinite linear;
   -moz-animation: rotation .6s infinite linear;
   -o-animation: rotation .6s infinite linear;
   animation: rotation .6s infinite linear;
   border-left:6px solid rgba(0,174,239,.15);
   border-right:6px solid rgba(0,174,239,.15);
   border-bottom:6px solid rgba(0,174,239,.15);
   border-top:6px solid rgba(0,174,239,.8);
   border-radius:100%;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 2
}

@-webkit-keyframes rotation {
   from {-webkit-transform: rotate(0deg);}
   to {-webkit-transform: rotate(359deg);}
}
@-moz-keyframes rotation {
   from {-moz-transform: rotate(0deg);}
   to {-moz-transform: rotate(359deg);}
}
@-o-keyframes rotation {
   from {-o-transform: rotate(0deg);}
   to {-o-transform: rotate(359deg);}
}
@keyframes rotation {
   from {transform: rotate(0deg);}
   to {transform: rotate(359deg);}
}
